import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import StyledHeading from "../components/StyledHeading"

const Contact = ({ data }) => (
  <Layout>
    <SEO title="Contact" description="" />
    <div className="container px-6 mx-auto mt-16 md:mt-20">
      <StyledHeading tag="h1" text="Contact" size="text-3xl" />
      <form
        className="mt-10"
        action="https://getform.io/f/74ce30d9-64de-4f8c-a622-f2c47c059334"
        method="POST"
      >
        <label className="block mt-5">
          E-mail
          <input
            className="block w-full p-2 mt-5 text-gray-700 border rounded md:w-1/2"
            type="email"
            name="email"
            required
          />
        </label>
        <label className="block mt-5">
          Naam
          <input
            className="block w-full p-2 mt-5 text-gray-700 border rounded md:w-1/2"
            type="text"
            name="name"
            required
          />
        </label>
        <label className="block mt-5">
          Bericht
          <textarea
            className="block w-full h-40 p-2 mt-5 text-gray-700 border rounded md:w-1/2"
            name="message"
          />
        </label>
        <button
          className="mt-5 font-bold bg-primary hover:underline"
          type="submit"
        >
          Verstuur
        </button>
      </form>
    </div>
  </Layout>
)

export default Contact
